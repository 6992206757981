import firebase from "firebase"
import { navigate } from "gatsby"
import { hasSignedInUser } from "./user"

import { isBrowser } from "services/general"

export const handleSignOut = ({ redirect = true }) => {
  try {
    firebase.auth().signOut()
    if (isBrowser()) {
      sessionStorage.removeItem("userData")
      sessionStorage.removeItem("addresses")
      sessionStorage.removeItem("contextState")
      sessionStorage.removeItem(hasSignedInUser())
      sessionStorage.clear()
    }

    if (redirect) navigate("/")
  } catch (error) {
    console.error("Sign Out Error", error)
  }
}
